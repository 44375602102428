import Vue from 'vue/dist/vue.esm'
import App from '../profile-activity.vue'
import {start} from './sidebar.js'
import VueAxios from 'vue-axios'
import { securedAxiosInstance, plainAxiosInstance } from './axios/index.js'



Vue.use(VueAxios, {
  secured: securedAxiosInstance,
  plain: plainAxiosInstance
})

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("user_feed")) {

    const profileFeedApp = new Vue({
        start,
        securedAxiosInstance,
        plainAxiosInstance,
        el: '#user_feed',
        data: () => {
            return {
                message: "",
                varName: null
            }
        },
        components: {App}
    })
    console.log(profileFeedApp)
  }
  console.log('loaded')
  console.log("PROFILE FEED");
})